<template>
<div>
  <!-- Desktop Layout -->
  <div class = "container d-none d-xl-block d-lg-block d-md-none d-sm-none d-xs-none">
    <div class="fixed-Footer mt-8">
      <b-card class="border-top border-primary border-right-0 border-left-0 border-bottom-0 margin-0 padding-0 rounded-0">
        <div class = "row pt-0 pt-lg-5 pt-xl-5">
            <div class = "col-lg-5 col-md-12 col-sm-12 pl-0 ml-0">
                  <img class="footer-logo" src="./../assets/images/BN-Resized.png"/>
            </div>

           
            <div class = "col-lg-2 col-md-2 text-lg-left text-center"><h6 class="heavy-font-weight">Location</h6><p class="light-font-weight">4751 S Conway Rd.<br>Orlando, FL 32812</p></div>

            <div class = "col-lg-2 col-md-2 text-lg-left text-center"><h6 class="heavy-font-weight">Contact</h6><p class="light-font-weight">407-583-4463<br>info@bizzynate.com</p></div>
     
            <div class = "col-lg-2 col-md-2 text-lg-left text-center"><h6 class="heavy-font-weight">Hours</h6><p class="light-font-weight">Monday - Friday<br>9:00am - 5:00pm</p></div>     

            <div class = "col-lg-1 col-md-2 text-lg-left text-center">
              <h6 class="heavy-font-weight">Social</h6>
              <p class="light-font-weight text-left">
                <a href="https://www.facebook.com/bizzynatecreativeinc" target="_blank">
                  <i class="fab fa-18x fa-facebook-f primary-Color p-2"></i>
                </a>
                <a href="https://www.linkedin.com/company/bizzynate-creative" target="_blank">
                  <i class="fab fa-18x fa-linkedin-in primary-Color p-2"></i>
                </a>
              </p>
            </div>

        </div>
        <div class="row">
                            <b-card-text>
                    <p style="font-size: 12px;" class="light-font-weight">&#169; Copyright 2021 BizzyNate Creative, Inc.</p>
                  </b-card-text>
                  </div> 
      </b-card>
    </div>
  </div>


  <!-- Mobile Layout -->
    <div class="mt-5 d-block d-xs-block d-sm-block d-md-block d-lg-none d-xl-none hide-X">
      <div style="max-width: 90vw; margin:auto;">
        <div class = "row px-0 mx-3 pt-5 border-top border-primary">
            <div style="width: 50%;" class = "col-sm-6 mx-0 pl-0"><h6 class="heavy-font-weight pb-0 mb-0">Location</h6><p class="light-font-weight footer-text">4751 S Conway Rd.<br>Orlando, FL 32812</p></div>
            <div style="width: 50%;" class = "col-sm-6 mx-0"><h6 class="heavy-font-weight pb-0 mb-0">Contact</h6><p class="light-font-weight footer-text">407-583-4463<br>info@bizzynate.com</p></div>
            <div style="width: 50%;" class = "col-sm-6 mx-0 pl-0"><h6 class="heavy-font-weight pb-0 mb-0">Hours</h6><p class="light-font-weight footer-text">Monday - Friday<br>9:00am - 5:00pm</p></div>
            <div style="width: 50%;" class = "col-sm-6 mx-0">
              <h6 class="heavy-font-weight mb-0">Social</h6>
              <p class="light-font-weight">
                <a href="https://www.facebook.com/bizzynatecreativeinc" target="_blank">
                  <i class="fab fa-18x fa-facebook-f primary-Color pt-2 pr-2 pb-2 pl-0"></i>
                </a>
                <a href="https://www.linkedin.com/company/bizzynate-creative" target="_blank">
                  <i class="fab fa-18x fa-linkedin-in primary-Color p-2"></i>
                </a>
              </p>
            </div>
        </div>
        </div>
        <div class = "row pt-0 mt-0">
          <div class="col-sm-12" style="text-align:center;">
            <img class="footer-logo-mobile" src="./../assets/images/BN-Resized.png"/>
            <p style="font-size: 12px;" class="light-font-weight pb-5">&#169; Copyright 2021 BizzyNate Creative, Inc.</p>
          </div>
        </div>
    </div>
</div>

</template>

<script>

  export default {
    data() {
      return {
      }
    },
    methods: {

    }
  }
</script>