<template>
<div>
  <div class = "container">

      <div class="row ml-0 mr-0">
          <div class = "col-lg-6 mt-10 proxima order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1">
            <div class="contact-wrapper">
              <h1 class="aller-bd">Maitenance & Hosting</h1>
              <p class="custom-body light-font-weight mt-4">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.
              </p>
            </div>
          </div>
          <div class = "col-lg-6 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2">
            <img class="width-full-auto-height" src="../assets/images/maitenance/Maitenance.svg" alt="Development Page Banner"/>
          </div>
      </div>

  </div>

    <div class ="row bg-primary-op mt-10 text-center ml-0 mr-0">
      <div class="col-lg-12">
        <h5 class="custom-accent-header proxima pt-9 pb-2">PRICING PLANS</h5>
        <h3 class="custom-sub-header aller-bd">Web Hosting Plans</h3>
        <div class ="design-wrapper">
          <p class="custom-body text-left mt-4">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
              At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
          </p>
        </div>
      </div>
    </div>

<div class="bg-full-width">
  <div class = "container">
      <div class="row text-center ml-0 mr-0 pt-8 pb-10">
        <div class ="col-lg-4 ml-0 mr-0 pl-0">
            <b-card
              tag="article"
              class="mb-2 pt-5 border-0 box-shadow hosting-wrapper"
              no-body
              >
              <img class="hosting-thumbnail mt-5 pb-2" src ="../assets/images/110x85.png" alt="Placeholder Image"/>
          
              <h5 class="custom-small-header aller-bd pt-3 pb-3">Basic</h5>
              <b-card-text class="text-left hosting-small-body proxima hosting-text-wrapper pb-6">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.
              </b-card-text>

              <b-button class="mt-6" block href="#" variant="primary">Start Now</b-button>
            </b-card>
            

        </div>
        <div class ="col-lg-4 ml-0 mr-0">

            <b-card
              tag="article"
              class="mb-2 pt-5 border-0 box-shadow hosting-wrapper"
              no-body
             >
              <img class="hosting-thumbnail mt-5 pb-2" src ="../assets/images/110x85.png" alt="Placeholder Image"/>
          
              <h5 class="custom-small-header aller-bd pt-3 pb-3">Standard</h5>
              <b-card-text class="text-left hosting-small-body proxima hosting-text-wrapper pb-6">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.
              </b-card-text>

              <b-button class="mt-6" block href="#" variant="primary">Start Now</b-button>
            </b-card>
            
        </div>
        <div class ="col-lg-4 ml-0 mr-0">
            <b-card
              tag="article"
              class="mb-2 pt-5 border-0 box-shadow hosting-wrapper"
              no-body
              >
              <img class="hosting-thumbnail mt-5 pb-2" src ="../assets/images/110x85.png" alt="Placeholder Image"/>
          
              <h5 class="custom-small-header aller-bd pt-3 pb-3">Premium</h5>
              <b-card-text class="text-left hosting-small-body proxima hosting-text-wrapper pb-6">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.
              </b-card-text>

              <b-button class="mt-6" block href="#" variant="primary">Start Now</b-button>
            </b-card>
            
        </div>
      </div>

    </div>
</div>


  <div class="row ml-0 mr-0 bg-secondary">
    <div class="col-lg-12 text-center">
      <h4 class="white-small-header aller-bd mt-5 mb-5 pt-2 pb-2">Need more? Get a custom hosting solution tailored to your specific needs! <b-icon-arrow-right class="ml-5" variant="white" scale="2"></b-icon-arrow-right></h4>
    </div>
  </div>



    <div class ="row bg-primary mr-0 ml-0 pr-0 pl-0" style="min-height: 600px;">
        <div class ="col-lg-12 text-center mt-5 pt-7">
              <h5 class="white-accent-header proxima">WHAT'S INCLUDED</h5>
              <h3 class="white-sub-header aller-bd pt-3 pb-6">Hosting Plan Details</h3>
        <div class = "container">
          <div class ="row text-center">
            <div style="width: 100%;">
              <b-card class="text-center bg-primary border-top-0 border-bottom-0 border-left-0 border-right-0 proxima">
                <b-tabs pills align="center" class="border-bottom-0">
                  <b-tab title="Basic" active>
                    <div class ="row ml-0 mr-0 pb-10">
                      <div class="col-lg-3 hosting-body text-left pt-8">
                        <div class="hosting-plan-wrapper">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.
                        </div>
                      </div>
                      <div class="col-lg-3 hosting-body text-left pt-8">
                        <div class="hosting-plan-wrapper">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.
                        </div>
                      </div>
                      <div class="col-lg-3 hosting-body text-left pt-8">
                        <div class="hosting-plan-wrapper">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.
                        </div>
                      </div>
                      <div class="col-lg-3 hosting-body text-left pt-8">
                        <div class="hosting-plan-wrapper">
                          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Standard"><b-card-text>Website Design</b-card-text></b-tab>
                  <b-tab title="Premium"><b-card-text>Web Application</b-card-text></b-tab>
                </b-tabs>
              </b-card>
            </div>
          </div>
        </div>  
      </div>
    </div>

      <div class = "container">

      <div class="row ml-0 mr-0 mt-10 mb-5 text-left">
          <div class = "col-lg-6">
            <img src="../assets/images/690x850.png" alt="Placeholder Image"/>
          </div>
          <div class = "col-lg-6 mt-15 proxima">
            <div class="contact-wrapper">
              <h5 class="custom-accent-header proxima">MAITENANCE</h5>
              <h1 class="aller-bd mt-4 mb-4">Monthly Updates</h1>
              <p class="custom-body light-font-weight mt-4">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.
              </p>
              <h3 class="aller-bd mt-4 mb-4">Starting at $125/month!</h3>
              <button class="btn btn-lg btn-primary">Schedule a Consultation</button>
            </div>
          </div>
      </div>

  </div>

</div>
</template>

<script>




export default {
  name: 'Projects',
  components: {},
  
}




</script>

<style scoped>
/deep/ .nav-link  {
  color: var(--white);
  margin: 0px 20px 0px 20px;
  padding: 5px 25px 5px 25px;
}

/deep/ .nav-link.active {
  border: 2px solid var(--white);
}

</style>
