<template>
<div>
  <div class = "container">

      <div class="row mr-0 ml-0 pr-0 pl-0 mt-5 mt-lg-0">
          <div class = "col-lg-6 col-md-6 mt-5 mt-xxl-14 mt-xl-10 mt-lg-6 proxima order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1">
            <div class="contact-wrapper">
              <h1 class="aller-bd">About Us</h1>
              <p class="custom-body mt-4">
                Since 2006, we have had the pleasure of designing and developing websites, planning and implementing web strategies, and creating custom applications for clients ranging from small, local businesses to multinational corporations. 
                Our work has spanned the globe with nonprofit, political, and advocacy organizations in 34 different countries, helping organizations to further their missions through their web presence and public engagement. 
                <br><br>
                Our small but mighty team has a passion for balance between design and functionality—never compromising one for the other. We thrive on the variety and innovation that this industry brings and love to solve problems with technology. 
                We are civically-engaged, talented, driven, informed on the latest trends, deeply committed to quality, and constantly striving to be early adopters in order to keep our clients on the cutting edge.
              </p>
            </div>
          </div>
          <div class = "col-lg-6 col-md-6 order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2 pt-6">
            <img class="width-full-auto-height" src="../assets/images/about/about.svg" alt="About Page Banner" />
          </div>
      </div>

    <div class ="row mr-0 ml-0 pr-0 pl-0">
      <div class ="col-lg-12 col-md-12 text-center mt-5 pt-0 mt-xl-4 mt-lg-2 mt-md-8 pt-lg-8 pt-md-8">
        <h5 class="custom-accent-header proxima pt-2 pb-2">OUR WORK</h5>
        <h3 class="custom-sub-header aller-bd">What We Do</h3>
    </div>

        <div class ="row mt-0 mt-lg-8 mt-md-8 mx-0"> 
                  <div class="col-xl-4 col-lg-6 offset-xl-2 mr-1 mb-1 col-md-12 col-sm-6 pr-lg-5 pt-2 mr-0 p-lg-4 p-xl-4 p-xxl-4 border border-secondary rounded text-left hide-border-mobile">
                      <div class="row mobile-border pt-4">
                          <div class="col-lg-6 col-md-6 col-sm-12 text-center pt-md-5 pt-lg-0 pt-xl-5 pt-xxl-2">
                              <img class="width-full-auto-height wwd_wrapper" src ="../assets/images/about/Branding.svg"/>
                          </div>

                          <div class="col-lg-6 col-md-6 pt-md-0 pt-lg-0 pt-xl-3 pt-lg-0 pt-xxl-4">
                            <h5 class="about-small-header aller-bd">Branding</h5>
                            <p class="about-small-body">
                                Branding is key to connecting with your customers. It tells them who you are and what makes you different from your competitors. 
                                We’ll help you create a brand that conveys your identity, professionalism, and trustworthiness to give you a strong foundation for your marketing and communication efforts.
                            </p>
                            </div>
                      </div>
                  </div>
        
                  <div class="col-xl-4 col-lg-6 offset-xl-2 mr-n1 mb-1 col-md-12 col-sm-6 pl-lg-5 pt-2 ml-0 p-lg-4 p-xl-4 p-xxl-4 border border-secondary rounded text-left hide-border-mobile">
                      <div class="row mobile-border pt-4">
                          <div class="col-lg-6 col-md-12 col-sm-12 text-center pt-md-5 pt-lg-0 pt-xl-5 pt-xxl-1">
                              <img class="width-full-auto-height wwd_wrapper" src ="../assets/images/about/Interactive.svg"/>
                          </div>
                          <div class="col-lg-6 col-md-6 pt-md-0 pt-lg-0 pt-xl-3 pt-lg-0 pt-xxl-4">
                            <h5 class="about-small-header aller-bd">Interactive Design</h5>
                            <p class="about-small-body">
                                Interactive design is more important than ever. With devices at everyone’s fingertips, it’s important to be able to create digital products that are appealing, effective, and intuitive. 
                                Our designs provide users with a guided, visionary experience that cater to their needs, while aligning with your vision.
                            </p>
                          </div>
                      </div>
                  </div>
            
        </div>

          <div class ="row mt-0 mx-0">


            <div class="col-xl-4 col-lg-6 offset-xl-2 mr-1 col-md-12 pr-lg-5 pt-2 mr-0 p-lg-4 p-xl-4 p-xxl-4 border border-secondary rounded text-left hide-border-mobile">
                <div class="row mobile-border pt-4">
                    <div class="col-lg-6 col-md-12 col-sm-12 text-center pt-md-5 pt-lg-0 pt-xl-5 pt-xxl-2">
                        <img class="width-full-auto-height wwd_wrapper" src ="../assets/images/about/WebApp.svg"/>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 pt-md-0 pt-lg-0 pt-xl-3 pt-lg-0 pt-xxl-4">
                      <h5 class="about-small-header aller-bd -pt-sm-10">Web Applications</h5>
                        <p class="about-small-body">
                            Web applications are the answer to anyone who needs functionality beyond typical website performance. We create custom designs that are user friendly and visually appealing. 
                            Our solutions are built using the latest technologies to ensure your product is optimized and secure.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-6 offset-xl-2 mr-n1 col-md-12 col-sm-6 pl-lg-5 pt-2 ml-0 p-lg-4 p-xl-4 p-xxl-4 border border-secondary rounded text-left hide-border-mobile">
                <div class="row mobile-border pt-4">
                    <div class="col-lg-6 col-md-12 col-sm-12 text-center pt-md-5 pt-lg-0 pt-xl-5 pt-xxl-1">
                      <img class="width-full-auto-height wwd_wrapper" src ="../assets/images/about/Marketing.svg"/>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 pt-md-0 pt-lg-0 pt-xl-3 pt-lg-0 pt-xxl-4">
                      <h5 class="about-small-header aller-bd">Marketing Campaigns</h5>
                      <p class="about-small-body">
                        Digital marketing campaigns play a large roll in online secondary. These efforts increase brand awareness and revenue through increased engagement.
                        Through various channels such as social media marketing and campaign landing pages, we help you target customers at the moment they are ready to convert.
                      </p>
                    </div>
                </div>
            </div>

        </div>


      </div>

    </div>

    <div class ="row bg-primary triangular-clip mr-0 ml-0 pr-0 pl-0">
        <div class ="col-lg-12 text-center mt-10 pt-15">
              <h5 class="white-accent-header proxima">VALUES</h5>
              <h3 class="white-sub-header aller-bd pt-3 pb-0 pb-lg-6 pb-md-6">What We Believe In</h3>


            <div class ="row mt-0 mt-lg-2 mt-md-5 mr-0 ml-0 pr-0 pl-0 ml-xl-15">
              <div class = "col-lg-4 col-md-6 col-sm-4 pr-0 pt-sm-5 pt-md-5 pt-lg-4"><img class="valueIcons" src="../assets/images/about/jobs.svg" /></div>
              <div class = "col-lg-6 col-md-6 col-sm-8 pt-sm-5 pt-md-5 pt-xxl-7 pt-xl-6">
                <div class ="about-wrapper">
                  <h5 class="white-small-header aller-bd">We Proudly Create American Jobs</h5>
                  <p class="white-body mt-3 text-left">
                      All of the work that we perform for our clients is done by the talented individuals we recruit, hire, and nurture as members of our family. 
                      And while our competitors attempt to save money by hiring cheap, foreign labor, we do not. We choose quality over profit. 
                      We strive to create American jobs, and prefer to work with full-time employees over contractors whenever possible to ensure both consistency and a place for our creative team members to call home.
                  </p>
                </div> 
              </div>  
            </div>


            <div class ="row mt-0 mt-lg-5 mt-md-5 mb-5 mr-0 ml-0 pr-0 pl-0 ml-xl-15">
              <div class = "col-lg-4 col-md-6 col-sm-4 pr-0"><img class="valueIcons" src="../assets/images/about/community.svg" /></div>
              <div class = "col-lg-6 col-md-6 col-sm-8 pt-xxl-9 pt-xl-8 pt-lg-5">
                <div class ="about-wrapper">
                  <h5 class="white-small-header aller-bd">We Support our Community</h5>
                  <p class="white-body mt-3 text-left">
                      Each year, BizzyNate Creative lovingly gives its time, talent and treasure to our favorite nonprofit organizations. 
                      Our employees are encouraged to volunteer their time and provided opportunities to do so through the organizations we support.
                  </p>
                </div> 
              </div>  
            </div>


            <div class ="row mt-0 mt-lg-5 mt-md-5 mb-8 mr-0 ml-0 pr-0 pl-0 ml-xl-15">
        
              <div class = "col-lg-4 col-md-6 col-sm-4 pr-0"><img class="valueIcons" src="../assets/images/about/diversity.svg" /></div>
              <div class = "col-lg-6 col-md-6 col-sm-8 pt-xxl-8 pt-xl-7 pt-lg-4">
                <div class ="about-wrapper">
                  <h5 class="white-small-header aller-bd">LGBTQ+ & Diversity</h5>
                  <p class="white-body mt-3 text-left">
                      BizzyNate Creative is a proud member of The Pride Chamber and is certified by the NGLCC as an LGBT Business Enterprise. 
                      We are committed to making diversity part of everything we do - from the people we work with to the products we make. 
                      We work hard to provide an equitable and inclusive workplace where our team members can be the best versions of themselves.
                  </p>
                </div> 
              </div>  
            </div>
        </div>
    </div>

    <div class ="row mr-0 ml-0 pr-0 pl-0">
      <div class ="col-lg-12 text-center mt-4 pt-4">
        <h5 class="custom-accent-header proxima pt-5 pb-2">MEET OUR TEAM</h5>
        <h3 class="custom-sub-header aller-bd">Who We Are</h3>
        <div class ="about-team-wrapper">
          <p class="custom-body text-left mt-4">
                Although small, our team is made up of individuals who exceptionally talented at what they do. 
                We’re a team of thinkers and explorers, who are always eager to discover new ways to solve issues with technology.
          </p>
        </div>
<div class="container">
        <div class ="row mt-8">

          <div class="col-lg-4 col-md-4 col-sm-12 p-0 pb-5 pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 text-center">
              <img class="rounded-img profile-img" src ="../assets/images/about/nate2.png"/>
              <h5 class="about-small-header aller-bd mt-5 mb-3">Nate West</h5>
              <h5 class="custom-accent-header proxima">President & CEO</h5>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 p-0 pb-5 pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 text-center">
              <img class="rounded-img profile-img" src ="../assets/images/about/Karen.jpg"/>
              <h5 class="about-small-header aller-bd mt-5 mb-3">Karen Johnson</h5>
              <h5 class="custom-accent-header proxima">Chief Operating Officer</h5>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 p-0 pb-5 pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 text-center">
              <img class="rounded-img profile-img" src ="../assets/images/about/ally2.png"/>
              <h5 class="about-small-header aller-bd mt-5 mb-3">Ally West</h5>
              <h5 class="custom-accent-header proxima">Office Manager</h5>
          </div>

        </div>

        <div class ="row mt-lg-8">
          <div class="col-lg-4 col col-sm-12 p-0 pb-5 pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 text-center">
              <img class="rounded-img profile-img" src ="../assets/images/about/janie.jpeg"/>
              <h5 class="about-small-header aller-bd mt-5 mb-3">Janie Ray</h5>
              <h5 class="custom-accent-header proxima">Project Manager & UI/UX Designer</h5>
          </div>
          <div class="col-lg-4 col-sm-12 p-0 pb-5 pb-xxl-0 pb-xl-0 pb-lg-0 pb-md-0 text-center">
              <img class="rounded-img profile-img" src ="../assets/images/about/zach.jpeg"/>
              <h5 class="about-small-header aller-bd mt-5 mb-3">Zach Almeida</h5>
              <h5 class="custom-accent-header proxima">Full Stack Developer</h5>
          </div>
          <div class="col-lg-4"></div>
        </div>

</div>
      </div>
    </div>

</div>

</template>

<script>




export default {
  name: 'Projects',
  components: {},
  
}




</script>

<style>


</style>
