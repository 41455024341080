import Vue from 'vue';
import Router from 'vue-router';

import Contact from './views/Contact.vue';
import Home from './views/Home.vue';
import Project from './views/Project.vue';
import Projects from './views/Projects.vue';
import About from './views/About.vue';
import Design from './views/Design.vue';
import Maitenance from './views/Maitenance.vue';
import Development from './views/Development.vue';
import NotFound from './views/404.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home
        },
        {
            path: '/contact',
            component: Contact
        },
        {
            path: '/projects',
            component: Projects
        },
        {
            path: '/project/:id',
            component: Project
        },
        {
            path: '/about',
            component: About
        },
        {
            path: '/design',
            component: Design
        },
        {
            path: '/maitenance',
            component: Maitenance
        },
        {
            path: '/development',
            component: Development
        },
        {
            path: '*',
            name: '404',
            component: NotFound
        }
    ],  scrollBehavior () {
        return { x: 0, y: 0 };
      }
})