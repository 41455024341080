<template>
<div class="container">
  <div class="sideBar d-none d-xl-block d-lg-block d-md-block d-sm-none d-xs-none"></div>
  <div class = "container pl-sm-7">

      <div class="row mr-0 ml-0 pr-0 pl-0 mt-5 mt-lg-0">
          <div class = "col-lg-6 mt-6 proxima order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1 pl-0">
            <div class="contact-wrapper">
              <h1 class="aller-bd pt-xxl-16 pt-xl-10 pt-lg-8">Contact Us</h1>
              <p class="custom-body mt-4">
                If you have a project you think we would be a good fit for, or even just an idea you'd like to talk about, we'd love to hear from you!
              </p>

            </div>
          </div>
          <div class = "col-lg-6 pt-sm-8 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2">
            <img class="width-full-auto-height" src="../assets/images/contact/Contact.svg" />
          </div>
      </div>

    <b-form @submit="submitForm" @reset="onReset">

      <div class="row">
          <div class="col-lg-6 pl-0 pr-0 pr-xl-5 pr-lg-5">
            <b-form-group id="input-group-1" class="mt-5 mb-5">
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  placeholder="First & Last Name"
                  required
                  class="border-bottom border-primary border-top-0 border-left-0 border-right-0 rounded-0"
                ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6 pl-0 pr-0">
            <b-form-group id="input-group-2" class="mt-5 mb-5">
              <b-form-input
                id="input-2"
                v-model="form.company"
                placeholder="Company Name"
                required
                class="border-bottom border-primary border-top-0 border-left-0 border-right-0 rounded-0"
              ></b-form-input>
            </b-form-group>
          </div>
      </div>

      <div class="row">
          <div class="col-lg-6 pl-0 pr-0 pr-xl-5 pr-lg-5">
            <b-form-group id="input-group-3" class="mt-5 mb-5">
              <b-form-input
                id="input-3"
                v-model="form.email"
                type="email"
                placeholder="Email Address*"
                required
                class="border-bottom border-primary border-top-0 border-left-0 border-right-0 rounded-0"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-6 pl-0 pr-0">
            <b-form-group id="input-group-4" class="mt-5 mb-5">
                  <b-form-select id="input-4" v-model="form.budget" required class="customDropdown">
                      <b-form-select-option :value="null">Estimated Budget*</b-form-select-option>
                      <b-form-select-option value="Under $5000">Under $5000</b-form-select-option>
                      <b-form-select-option value="$5000 - $10,000">$5000 - $10,000</b-form-select-option>
                      <b-form-select-option value="$10,000 - $15,000">$10,000 - $15,000</b-form-select-option>
                      <b-form-select-option value="$15,000 - $25,000">$15,000 - $25,000</b-form-select-option>
                      <b-form-select-option value="$25,000 - $50,000">$25,000 - $50,000</b-form-select-option>
                      <b-form-select-option value="$50,000+">$50,000+</b-form-select-option>
                  </b-form-select>
              </b-form-group>
          </div>

      <h5 class="custom-accent-header proxima">What can we help you with?</h5>
      </div>

        <b-form-group id="input-group-5" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
          size="lg"
            id="input-5"
            class="proxima"
            v-model="form.options"
            :options="contactOptions"
            :aria-describedby="ariaDescribedby"
            name="contactOptions"
              stacked
              style="column-count: 3; width:fit-content;"
          ></b-form-checkbox-group>
        </b-form-group>



<div class="row">
  <div class="col-12 pl-0 pr-0">
          <b-form-group id="input-group-6" class="mt-5 mb-5">
              <b-form-textarea
                id="input-6"
                v-model="form.message"
                placeholder="Describe your project, or just say hello!"
                required
                class="border-bottom border-primary rounded p-2"
                rows="5" 
              ></b-form-textarea>
          </b-form-group>
  </div>
</div>


      <div class ="responsive-align-right">
        <!-- <div class="g-recaptcha" data-sitekey="6LchIFoeAAAAALSxHMy6CI93wp2yTbildVmiCSho"></div> -->
        <b-button type="submit" variant="secondary" class="btn custom-btn btn-lg aller-bd">Send Message</b-button>
        <div class="py-5">{{statusText}}</div>
      </div>
    </b-form>
  </div>
</div>
</template>

<script>

import ContactService from '../services/ContactService.js';
const contactService = new ContactService;

  export default {
    data() {
      return {
        statusText: null,
        form: {
          subject: null,
          company: '',
          budget: null,
          options: [],
          name: '',
          email: '',
          message: ''
        },
        contactOptions: [
          {text:'Brand Strategy & Design' , value: 'Brand Strategy & Design'},
          {text:'UI & UX Design' , value: 'UI & UX Design'},
          {text:'eCommerce' , value: 'eCommerce'},
          {text:'Web Design & Development' , value: 'Web Design & Development'},
          {text:'App Design & Development' , value: 'App Design & Development'},
          {text:'Marketing & Ad Campaigns' , value: 'Marketing & Ad Campaigns'},
          {text:'Print Design' , value: 'Print Design'},
          {text:'Web Hosting' , value: 'Web Hosting'},
          {text:'Web Maintenance' , value: 'Web Maintenance'},
        ]
      }
    },
    methods: {
      submitForm(event) {
        event.preventDefault();

        const data = { 
            name:this.form.name,
            company_name: this.form.company,
            budget: this.form.budget,
            options: this.form.options,
            email:this.form.email,
            message:this.form.message
        };

        //console.log(data);
        
        contactService.submitContact(data)
            .then(res => {
              console.log(res);
              this.statusText = "Thank you for your submission! A member of our team will be in touch with you shortly.";
            })
            .catch(err => {
              console.log(err);
              this.statusText = "Something isn't quite right, could you try that again?";
            });

      },
      onReset(event) {
        event.preventDefault()

      }
    },
  }
</script>

<style >

  .customDropdown {
      color: black;
      text-align: left;
      border-color: var(--primary);
      border-top: 0px;
      border-right: 0px;
      border-left: 0px;
      border-radius: 0px;
      padding-left: 0px;
      font-size: large;
  }

.custom-control-input:checked ~ .custom-control-label::before {
    color: var(--primary);
    border-color: var(--primary);
    background-color: var(--primary);

 }

 .custom-control-input ~ .custom-control-label::before {
    border-color: var(--primary);
    border-width: 2px;

 }

 .custom-checkbox.b-custom-control-lg, .input-group-lg .custom-checkbox {
   font-size: 1.0rem;
   line-height: 1.4rem;
   width: max-content;
   padding:1rem;
   min-width: 20vw;
 }

</style>