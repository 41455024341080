<template>

  <div class = "container">

      <div class="row mr-0 ml-0 pr-0 pl-0 mt-5 mt-lg-0">
          <div class = "col-lg-6 col-md-6 mt-5 mt-xxl-18 mt-xl-14 mt-lg-10 proxima order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1">
            <div class="contact-wrapper">
              <h1 class="aller-bd">Projects</h1>
              <p class="custom-body mt-4">
                Our team has been fortunate to have the opportunity to work with a variety of clients on a number of different projects. 
                We take pride in creating custom solutions and designs that keep users engaged and create longstanding relationships.
                <br><br> 
                Here you can view a small selection of some of our recent projects to give you a better idea of what we can do.
              </p>
            </div>
          </div>
          <div class = "col-lg-6 col-md-6 order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2 pt-6">
            <img class="width-full-auto-height" src="../assets/images/projects/Projects.svg" alt="Project Page Banner"/>
          </div>
      </div>

<div class="mx-5">
    <div class ="row mt-lg-10 mt-sm-5 text-center">

        <h1 class="aller-bd mt-6 mt-lg-2 mb-2 width-full">Our Work</h1>
          <div class="border-top border-secondary width-full mx-0 mt-4"></div>
              <div class ="row">
              <template v-for="project in projects">
                    <div v-if="project.type != 86" class = "col-xl-4 col-lg-6 col-md-12 text-left mt-5 px-xl-5" :key="project.id">
                      <div class ="project-description-wrapper">
                          <div class="project-image-wrapper shadow rounded">
                            <img v-if="project.image_url" @click="viewModalImage(project.image_url)" :src="require(`../assets/${project.image_url}`)" :alt="`${project.name} Image`" style="max-width: inherit; width:100%"/>
                          </div>
                          <h5 class="custom-small-header aller-bd pt-4 pb-2">{{project.name}}</h5>
                          <h6 class="project-small-header pt-1 pb-2">{{project.headline}}</h6>
                            <p class="custom-body proxima">
                              {{project.description}}
                            </p>
                      </div>
                    </div>
              </template>
              </div>

    </div>
    </div>

  <b-modal scrollable modal-class="modal-fullscreen" id="project-image-modal" title="Project Details">
      <img v-if="selectedModalImage" :src="require(`../assets/${this.selectedModalImage}`)" style="width: 100%"/>
  </b-modal>

  </div>
</template>

<script>

import ProjectService from '../services/ProjectService.js';
const projectService = new ProjectService;


export default {
  name: 'Projects',

  data() {
      return {
          projects: [],
          selectedModalImage: "",
        };
  },

  components: {},

  methods: {
    viewModalImage(image_url) {
      this.selectedModalImage = image_url;
      this.$bvModal.show('project-image-modal');
    },
  },



  mounted() {
    projectService.getProjects()
    .then(res => {
      this.projects = res.data;
      console.log(this.projects);
    })
    .catch(err => {
      console.log(err);
    });
}
  
}




</script>


<style scoped>
.nav-link a{
  color: var(--primary) !important;
}

</style>
